import axios from "./store/axios";
import vanillaAxios from "axios";
import { getAddressApiKey } from "./config.json";
import { trackAnalyticsEvent } from "./util/util";

class ApiClient {
  async loginUser(payload) {
    const { data } = await axios.post("/auth/user", payload);

    trackAnalyticsEvent("User", "Login");

    return data;
  }

  async refreshTokenForUser() {
    const { data } = await axios.post("/auth/refreshTokenForUser");

    return data;
  }

  async registerPatient(payload) {
    const { data } = await axios.post("/patients/register", payload);

    trackAnalyticsEvent("User", "Register", "Patient Registered");

    return data;
  }

  async registerCarer(payload) {
    const { data } = await axios.post("/carers/register", payload);

    trackAnalyticsEvent("User", "Register", "Carer Registered");

    return data;
  }

  async registerDoctor(doctorFormData) {
    const { data } = await axios.post("/doctors/register", doctorFormData);

    trackAnalyticsEvent("User", "Register", "Doctor Registered");

    return data;
  }

  async createPatient(patientFormData) {
    const { data } = await axios.post(
      "patients/registerfromdoctor",
      patientFormData
    );

    trackAnalyticsEvent("User", "Register", "Patient Registered from Doctor");

    return data;
  }

  async uploadPrescription(prescriptionFormData) {
    const { data } = await axios.post(
      "prescriptions/submit",
      prescriptionFormData
    );

    trackAnalyticsEvent("Prescription", "Upload", `Is T21: ${data.isT21Patient}`);

    return data;
  }

  async uploadPrescriptionAsClinicUser(organisationId, prescriptionFormData) {
    const { data } = await axios.post(
      `prescriptions/SubmitByOrganisationEmployee?organisationId=${organisationId}`,
      prescriptionFormData
    );

    return data;
  }

  async bulkUploadPrescriptionImages(prescriptionFormData) {
    const { data } = await axios.post(
      "prescriptions/submitMultipleByDoctor",
      prescriptionFormData
    );

    return data;
  }

  async uploadProtoPrescriptionAsOrganisationEmployee(organisationId, prescriptionFormData) {
    const { data } = await axios.post(
      `protoprescriptions/SubmitByOrganisationEmployee?organisationId=${organisationId}`,
      prescriptionFormData
    );

    return data;
  }

  async promoteProtoPrescription(organisationId, prescriptionFormData) {
    const { data } = await axios.post(
      `protoprescriptions/AddPatientsDetails?organisationId=${organisationId}`,
      prescriptionFormData
    );

    return data;
  }

  async deleteProtoPrescription(organisationId, protoPrescriptionId) {
    const { data } = await axios.delete(
      `protoprescriptions/delete?organisationId=${organisationId}&id=${protoPrescriptionId}`
    );

    return data;
  }

  async bulkUploadProtoPrescriptionAsOrganisationEmployee(organisationId, prescriptionFormData) {
    const { data } = await axios.post(
      `protoprescriptions/SubmitMultipleByOrganisationEmployee?organisationId=${organisationId}`,
      prescriptionFormData
    );

    return data;
  }

  // prescriptions
  async getActivePrescriptions() {
    const { data } = await axios.get("/prescriptions/active");

    return data;
  }

  async getProtoPrescriptions(organisationId, organisationEmployeeId, parameter = "", type = "", page = 1, pageSize = 5, sortField = 'id', sortDirection = 0) {
    const { data } = await axios.get(`/protoprescriptions/FilterForPrescriber?organisationId=${organisationId}&organisationEmployeeId=${organisationEmployeeId}&filterParameter=${parameter}&filterType=${type}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}`);

    return data;
  }

  async getToBeAssignedPrescriptions(organisationId, organisationEmployeeId, parameter = "", type = "", page = 1, pageSize = 5, sortField = 'id', sortDirection = 0) {
    const { data } = await axios.get(`/prescriptions/FilterUnassignedForPrescriber?organisationId=${organisationId}&organisationEmployeeId=${organisationEmployeeId}&filterParameter=${parameter}&filterType=${type}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}`);

    return data;
  }

  async getAwaitingRewriteOrganisationPrescriptions(organisationId, organisationEmployeeId, parameter = "", type = "", page = 1, pageSize = 5, sortField = 'rewriteDate', sortDirection = 1) {
    const { data } = await axios.get(`/prescriptions/GetAllAwaitingRewriteForPrescriber?organisationId=${organisationId}&organisationEmployeeId=${organisationEmployeeId}&filterParameter=${parameter}&filterType=${type}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}`);

    return data;
  }

  async getActivePrescriptionsForOrganisation(organisationId, organisationEmployeeId, parameter = "", type = "", page = 1, pageSize = 5, sortField = 'createdDate', sortDirection = 0) {
    const { data } = await axios.get(`/prescriptions/GetAllActiveForPrescriber?organisationId=${organisationId}&organisationEmployeeId=${organisationEmployeeId}&filterParameter=${parameter}&filterType=${type}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}`);

    return data;
  }

  async getPastPrescriptionsForOrganisation(organisationId, organisationEmployeeId, parameter = "", type = "", page = 1, pageSize = 5, sortField = 'createdDate', sortDirection = 0) {
    const { data } = await axios.get(`/prescriptions/GetAllPastForPrescriber?organisationId=${organisationId}&organisationEmployeeId=${organisationEmployeeId}&filterParameter=${parameter}&filterType=${type}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}`);

    return data;
  }

  async getBasicClinicPatients(organistaionId) {
    const { data } = await axios.get(`/tempPatients/GetAllForOrganisation?organisationId=${organistaionId}`);

    return data;
  }

  async getFullClinicPatients(organistaionId) {
    const { data } = await axios.get(`/patients/GetAllForOrganisation?organisationId=${organistaionId}`);

    return data;
  }

  async getPastPrescriptions() {
    const { data } = await axios.get("/prescriptions/past");

    return data;
  }

  // account
  async getPatientDetails() {
    const { data } = await axios.get("/auth/getuserdata");

    return data;
  }

  async acceptCookies(id) {
    const { data } = await axios.patch(`users/acceptcookies?id=${id}`);

    return data;
  }

  async updatePatientDetails(patientDetails) {
    const { data } = await axios.patch(
      "/patients/updateaccount",
      patientDetails
    );

    return data;
  }

  async getCarerDetails() {
    const { data } = await axios.get("/auth/getuserdata");

    return data;
  }

  async updateCarerDetails(carerDetails) {
    const { data } = await axios.patch("/carers/updateaccount", carerDetails);

    return data;
  }

  async getDoctorDetails() {
    const { data } = await axios.get("/auth/getuserdata");
    return data;
  }

  async getClinicUserDetails() {
    const { data } = await axios.get("/auth/getuserdata");
    return data;
  }

  async updateDoctorDetails(formData) {
    const { data } = await axios.patch("/doctors/updateaccount", formData);

    return data;
  }

  async uploadIds() {
    const { data } = await axios.post("/users/verifyid");

    return data;
  }

  async requestPasswordReset(formData) {
    const { data } = await axios.post("/users/requestpasswordreset", formData);

    trackAnalyticsEvent("User", "Forgot Password");

    return data;
  }

  async resetPassword(formData) {
    const { data } = await axios.post("/users/resetpassword", formData);

    return data;
  }

  async changePassword(formData) {
    const { data } = await axios.post("/users/changepassword", formData);

    return data;
  }

  async checkGmc(formData) {
    const { data } = await axios.post("/doctors/gmc", formData);

    return data;
  }

  async checkCqc(formData) {
    const { data } = await axios.post("/doctors/cqc", formData);

    return data;
  }

  async verifyEmail(formData) {
    const { data } = await axios.post("/users/verify", formData);

    return data;
  }

  async getDoctorsPatients(doctorId) {
    const { data } = await axios.get(
      `/prescriptions/getpatientsdetails?doctorId=${doctorId}`
    );

    return data;
  }

  async reportProblemWithOrder(formData) {
    const { data } = await axios.post("/orders/reportpreviousorder", formData);

    return data;
  }

  async payForOrder(formData) {
    const { data } = await axios.post(
      `/transactions/requestcallback`,
      formData
    );

    trackAnalyticsEvent("User", "Request Callback");

    return data;
  }

  async getOrderById(id) {
    const { data } = await axios.get(`/orders/getbynumber?number=${id}`);

    return data;
  }

  async getPrescribersForOrganisationEmployee(organisationId) {
    const { data } = await axios.get(`/prescribers/GetAllByOrganisation?organisationId=${organisationId}`);

    return data;
  }

  async getPatientByClinicId(organisationId, clinicPatientId) {
    const { data } = await axios.get(`/patients/CheckIfExistsForClinicUser?organisationId=${organisationId}&clinicIdNumber=${clinicPatientId}`);

    return data;
  }

  async getTempPatientByClinicId(organisationId, clinicPatientId) {
    const { data } = await axios.get(`/temppatients/CheckIfExistsForClinicUser?organisationId=${organisationId}&clinicIdNumber=${clinicPatientId}`);

    return data;
  }

  async getOrderToBePaid(id) {
    const { data } = await axios.get(`/orders/GetByNumberForPayment?number=${id}`);

    return data;
  }

  async getOrderToBePaidProtected(id) {
    const { data } = await axios.get(`/orders/GetByNumberForPaymentProtected?number=${id}`);

    return data;
  }

  async refer(formData) {
    const { data } = await axios.post("/referrals", formData);

    return data;
  }

  async resubmitOrder(formData) {
    const { data } = await axios.post("stockcheck/checkstock", formData);

    return data;
  }

  async getPatientDataForCompleteAccount(secretId) {
    const { data } = await axios.get(
      `patients/getbysecretid?secretId=${secretId}`
    );

    return data;
  }

  async completePatientAccount(formData) {
    const { data } = await axios.patch(
      "patients/updateaccountfromdoctor",
      formData
    );

    return data;
  }

  async completeCarerAccount(formData) {
    const { data } = await axios.patch(
      "carers/updateaccountfromdoctor",
      formData
    );

    return data;
  }

  async getAddress(searchText) {
    const { data } = await vanillaAxios.get(
      `https://api.getAddress.io/find/${searchText}?api-key=${getAddressApiKey}&expand=true&sort=true`
    );

    return data;
  }

  async checkPatientDobClinicNumber({ clinicIdNumber, dateOfBirth, secretId }) {
    const { data } = await axios.get(`/patients/GetByClinicIdNumberAndDateOfBirth?clinicIdNumber=${clinicIdNumber}&dateOfBirth=${dateOfBirth}&secretId=${secretId}`);

    return data;
  }

  async checkVerificationStatus() {
    const { data } = await axios.get("/users/checkidverificationstatus");

    return data;
  }

  async resetVerificationStatus() {
    await axios.patch("/users/resetidverificationstatus");
  }

  async resendVerificationEmail() {
    await axios.post("/users/sendverificationemail");
  }

  async getDoctorDataForCustomer(id) {
    const { data } = await axios.get(
      `/prescriptions/getnewestdoctorsdata?patientId=${id}`
    );

    return data;
  }

  async getMerchantSessionKey() {
    return await axios.get(`/transactions/merchantsessionkey`);
  }

  async payOrder(transactionInfo) {

    axios.defaults.headers["Content-Type"] = "application/json";

    let result = await axios.post(`/transactions/create`, transactionInfo);

    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    return result;
  }

  async requestPaymentLink(orderGuid) {
    axios.defaults.headers["Content-Type"] = "application/json";

    await axios.post('/transactions/requestpaymentlink', { orderGuid })

    axios.defaults.headers["Content-Type"] = "multipart/form-data";
  }

  async getAllFilteredPrescriptionsAsClinicUser(query, organisationId, organisationEmployeeId) {
    const { data } = await axios.get(`/prescriptions/GetAllFilteredForOrganisationEmployee?query=${query}&organisationId=${organisationId}&organisationEmployeeId=${organisationEmployeeId}`);

    return data
  }

  async getCurrentClinicDetails(userId) {
    const { data } = await axios.get(`/organisations/GetClinicDetailsForUser?userId=${userId}`);

    return data
  }

  async getAllByPatientsId(patientId) {
    const { data } = await axios.get(`/products/getAllByPatientId?patientId=${patientId}`)

    return data
  }

  async getRepeatPrescriptionPaymentIntent(patientId) {
    const { data } = await axios.get(`/transactions/GetRepeatPrescriptionPaymentIntent?patientId=${patientId}`)

    return data
  }

  async requestRepeatPrescription(payload) {

    axios.defaults.headers["Content-Type"] = "application/json";

    const { data } = await axios.post(`/prescriptions/RequestRepeatPrescription`, payload)

    axios.defaults.headers["Content-Type"] = "multipart/form-data";

    return data
  }

  //products

  async getAllProductsForClinicUser(filter, organisationId, organisationEmployeeId,) {
    const { data } = await axios.get(`/products/GetAllForClinicUser?filter=${filter}&organisationId=${organisationId}&organisationEmployeeId=${organisationEmployeeId}`);

    return data;
  }

  async getAllProductsForPatient(filter) {
    const { data } = await axios.get(`/products/GetAllForPatientFormulary?filter=${filter}`);

    return data;
  }

  async getAllTerpenes() {
    const { data } = await axios.get(`/terpenes/GetAll`);

    return data;
  }

  async getAllSuppliers(organisationId) {
    const { data } = await axios.get(`/suppliers/GetAllForClinicUser?organisationId=${organisationId}`);

    return data;
  }

  async getAllSuppliersForPatient() {
    const { data } = await axios.get(`/suppliers/GetAllForPatient`);

    return data;
  }

  async getAllProductImagesById(productId, organisationId) {
    const { data } = await axios.get(`/products/GetAllProductImagesByIdForClinicUser?productId=${productId}&organisationId=${organisationId}`);

    return data;
  }

  async getAllProductDocsById(productId, organisationId) {
    const { data } = await axios.get(`/products/GetAllProductDocumentsByIdForClinicUser?productId=${productId}&organisationId=${organisationId}`);

    return data;
  }

  async getAllProductImagesByIdForPatient(productId, organisationId) {
    const { data } = await axios.get(`/products/GetAllProductImagesByIdForPatient?productId=${productId}`);

    return data;
  }

  async getAllProductDocsByIdForPatient(productId, organisationId) {
    const { data } = await axios.get(`/products/GetAllProductDocumentsByIdForPatient?productId=${productId}`);

    return data;
  }
}

export const apiClient = new ApiClient();
