import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { BeforeWeStartHeader } from "../../../components/headers";

import { resetPatient } from "../../../store/slices/patientOnboardingSlice";
import { resetCarer } from "../../../store/slices/carerOnboardingSlice";

import {
  DetailsIcon,
  PrescriptionRoundIcon,
  UserIcon,
} from "../../../assets/icons";

function PatientBeforeWeStart(props) {
  const { setNextStep } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPatient());
    dispatch(resetCarer());
  });

  return (
    <section className="before_we_start">
      <BeforeWeStartHeader />
      <div className="before_we_start__left_slot before_we_start__left_slot--patient">
        <div className="before_we_start__overlay"></div>
      </div>
      <div className="before_we_start__right_slot">
        <div>
          <div className="before_we_start__title">
            <p className="label--caps--normal">Getting started</p>
            <h1 className="h3-a">Here’s what we need</h1>
            <p className="p4">
              Creating an account is simple and should take no more than a few
              minutes
            </p>
          </div>
          <div className="before_we_start__steps">
            <ol>
              <li>
                <DetailsIcon />
                <p>Confirm your details</p>
              </li>
              <li>
                <UserIcon />
                <p>Verify your account and ID</p>
              </li>
            </ol>
          </div>
          <div className="before_we_start__button">
            <PrimaryCta
              onClick={() => setNextStep(props.steps.whoIsItFor)}
              text="Start"
              color="orange"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PatientBeforeWeStart;
