import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import React from 'react'
import RepeatPrescriptionForm from '../../components/forms/repeatPrescriptionForm/RepeatPrescriptionForm'

const stripePromise = loadStripe("pk_live_LyWHhphdoXZVyd4iQt7NslRp00zO6hfppV");

export default function repeatPrescriptionPage() {
  return (
    <Elements stripe={stripePromise}>
      <RepeatPrescriptionForm />
    </Elements>
  )
}
