import { apiClient } from "../ApiClient";
import { callMapper, createFormData, getImageFromUrl } from "../util/util";
import { prescriptionConverter } from "../converters/PrescriptionConverter";
import {
  ClinicPrescriptionsExcludeCertainStatusesMapper,
  GetOrderToBePaidCallTypes,
  OrganisationPrescriptionsTypes,
  OrganisationPrescriptionsTypesToApiCallMapper,
} from "../enums";
import { usersConverter } from "../converters/UsersConverter";

class PrescriptionService {
  async getActivePrescriptions() {
    const activePrescriptions = await apiClient.getActivePrescriptions();

    prescriptionConverter.apiToClient(activePrescriptions);

    return prescriptionConverter.getProcessedPrescriptions();
  }

  async getPastPrescriptions() {
    const pastPrescriptions = await apiClient.getPastPrescriptions();

    prescriptionConverter.apiToClient(pastPrescriptions);

    return prescriptionConverter.getProcessedPrescriptions();
  }

  async getCurrentClinicDetails(userId) {
    return await apiClient.getCurrentClinicDetails(userId)
  }

  async getPrescriptionsForOrganisation(organisationId, organisationEmployeeId, status, parameter, type, pageParams) {
    const paginatedResult = await callMapper(OrganisationPrescriptionsTypesToApiCallMapper, status, apiClient.getProtoPrescriptions)(
      organisationId,
      organisationEmployeeId,
      parameter,
      type,
      pageParams.currentPage,
      pageParams.pageSize,
      pageParams.sortField,
      pageParams.sortDirection)
      ;

    if (status === OrganisationPrescriptionsTypes.ImageOnly) {
      prescriptionConverter.protoPrescriptionApiToClient(paginatedResult);
    }
    else
      prescriptionConverter.apiToClient(paginatedResult.items);

    const { items, ...restOfPaginatedResult } = paginatedResult;
    return {
      prescriptions: prescriptionConverter.getProcessedPrescriptions(callMapper(ClinicPrescriptionsExcludeCertainStatusesMapper, status, "undefined")),
      paginatedData: restOfPaginatedResult
    }
  }

  async getAllPrescriptions() {
    const activePrescriptions = await apiClient.getActivePrescriptions();
    const pastPrescriptions = await apiClient.getPastPrescriptions();

    const allPrescriptions = [...activePrescriptions, ...pastPrescriptions]
    prescriptionConverter.apiToClient(allPrescriptions);

    let processedPrescriptions = prescriptionConverter.getProcessedPrescriptions()

    let prescriptionsWithNoDuplicates = []

    /* remove duplicate prescriptions */
    processedPrescriptions.forEach(prescription => {
      if (!prescription.orderId) {
        prescriptionsWithNoDuplicates.push(prescription)
      }
      if (!prescriptionsWithNoDuplicates.find(p => p.orderId === prescription.orderId)) {
        prescriptionsWithNoDuplicates.push(prescription)
      }
    })

    return prescriptionsWithNoDuplicates;
  }

  async uploadPrescription(prescription, patientId, doctorId, isT21Patient) {
    const prescriptionBlob = await getImageFromUrl(
      prescription.url,
      prescription.name
    );

    const formData = createFormData({
      patientId,
      prescription: prescriptionBlob,
      confirmedConsent: prescription.confirmedConsent,
      doctorId,
      isT21Patient,
    });

    const response = await apiClient.uploadPrescription(formData);

    return response;
  }

  async uploadPrescriptionAsClinicUser({ prescription, patientId, organisationId, organisationEmployeeId, isT21Patient, clinicIdNumber, tempPatientId, tempPatientEmail = "", tempPatientTelephoneNo = "", envelopeTrackingCode = "" }) {
    const prescriptionBlob = await getImageFromUrl(
      prescription.url,
      prescription.name
    );

    const formData = createFormData({
      patientId,
      prescription: prescriptionBlob,
      organisationEmployeeId,
      isT21Patient,
      clinicIdNumber,
      tempPatientId,
      tempPatientEmail,
      tempPatientTelephoneNo,
      envelopeTrackingCode
    });

    const response = await apiClient.uploadPrescriptionAsClinicUser(organisationId, formData);

    return response;
  }

  async uploadProtoPrescriptionAsOrganisationEmployee({ prescription, organisationId, organisationEmployeeId, envelopeTrackingCode = "" }) {
    const prescriptionBlob = await getImageFromUrl(
      prescription.url,
      prescription.name
    );

    const formData = createFormData({
      prescription: prescriptionBlob,
      organisationEmployeeId,
      envelopeTrackingCode
    });

    const response = await apiClient.uploadProtoPrescriptionAsOrganisationEmployee(organisationId, formData);

    return response;
  }

  async promoteProtoPrescription({ protoPrescriptionId, organisationId, patientId, isT21Patient, clinicIdNumber, tempPatientId, tempPatientEmail = "", tempPatientTelephoneNo = "", envelopeTrackingCode = "" }) {

    const formData = createFormData({
      protoPrescriptionId,
      patientId,
      isT21Patient,
      clinicIdNumber,
      tempPatientId,
      tempPatientEmail,
      tempPatientTelephoneNo,
      envelopeTrackingCode
    });

    const response = await apiClient.promoteProtoPrescription(organisationId, formData);

    return response;
  }

  async deleteProtoPrescription(organisationId, id) {


    const response = await apiClient.deleteProtoPrescription(organisationId, id);

    return response;
  }

  async bulkUploadProtoPrescriptionAsOrganisationEmployee({ prescriptions, organisationId, organisationEmployeeId, envelopeTrackingCode = "" }) {
    const prescriptionsBlobs = [];

    for (const prescription of prescriptions) {
      const prescriptionBlob = await getImageFromUrl(
        prescription.url,
        prescription.name
      );

      prescriptionsBlobs.push(prescriptionBlob);
    }

    const formData = createFormData({
      prescriptions: prescriptionsBlobs,
      organisationEmployeeId,
      envelopeTrackingCode
    });

    const response = await apiClient.bulkUploadProtoPrescriptionAsOrganisationEmployee(organisationId, formData);

    return response;
  }

  async bulkUploadPrescriptionImages(prescriptions, doctorId, confirmedConsent, envelopeTrackingCode) {
    const prescriptionsBlobs = [];

    for (const prescription of prescriptions) {
      const prescriptionBlob = await getImageFromUrl(
        prescription.url,
        prescription.name
      );

      prescriptionsBlobs.push(prescriptionBlob);
    }

    const formData = createFormData({
      prescriptions: prescriptionsBlobs,
      confirmedConsent: confirmedConsent,
      doctorId,
      envelopeTrackingCode,
    });

    const response = await apiClient.bulkUploadPrescriptionImages(formData);

    return response;
  }

  async reportProblemWithOrder(report) {
    const formData = createFormData(report);

    return apiClient.reportProblemWithOrder(formData);
  }

  async payForOrder(orderId) {
    const formData = createFormData({ orderId });

    await apiClient.payForOrder(formData);
  }

  async getOrderToBePaid(id, callType) {
    if (callType === GetOrderToBePaidCallTypes.GetUnprotectedOrderTobePaid) {
      const order = await apiClient.getOrderToBePaid(id);

      return prescriptionConverter.orderToBePaidApiToClient(order);
    }
    if (callType === GetOrderToBePaidCallTypes.GetProtectedOrderTobePaid) {
      const order = await apiClient.getOrderToBePaidProtected(id);

      return prescriptionConverter.orderToBePaidApiToClient(order);
    }
    return undefined;
  }

  async payOrder(transactionData) {
    await apiClient.payOrder({
      billingAddress: {
        address1: transactionData.address1,
        address2: transactionData.address2,
        address3: "",
        city: transactionData.address2,
        country: "gb",
        postalCode: transactionData.zipCode,
      },
      amount: null,
      cardIdentifier: transactionData.cardIdentifier,
      currency: "GBP",
      customerEmail: transactionData.email,
      customerFirstName: transactionData.cardHolderFirstName,
      customerLastName: transactionData.cardHolderLastName,
      customerPhone: transactionData.telephoneNo,
      description: `Payment made via Customer app for order : ${transactionData.orderNumber}`,
      merchantSessionKey: transactionData.merchantSessionKey,
      orderId: transactionData.orderId,
      prescriptionId: transactionData.prescriptionId,
      userId: transactionData.userId,
    })

  }

  async resubmitOrder(orderId) {
    const formData = createFormData({ orderId });
    const response = await apiClient.resubmitOrder(formData);

    return response;
  }

  getDoctorIdFromPrescriptions(activePrescriptions, pastPrescriptions) {
    // look for a doctor in active prescriptions
    const activePrescription = activePrescriptions.find(
      (prescription) => prescription.doctorId
    );

    if (activePrescription) return activePrescription.doctorId;

    // look for a doctor in past prescriptions
    const pastPrescription = pastPrescriptions.find(
      (prescription) => prescription.doctorId
    );

    if (pastPrescription) return pastPrescription.doctorId;

    return null;
  }

  async requestPaymentLink(orderNumber) {
    await apiClient.requestPaymentLink(orderNumber)
  }

  async getPrescribersForOrganisationEmployee(organisationId) {
    return await apiClient.getPrescribersForOrganisationEmployee(organisationId)
  }

  async getPatientByClinicId(organisationId, clinicPatientId) {
    const patient = await apiClient.getPatientByClinicId(organisationId, clinicPatientId)
    const tempPatient = await apiClient.getTempPatientByClinicId(organisationId, clinicPatientId)
    if (patient != null && patient.id) {
      return usersConverter.patientApiToClientClinicUser(patient);
    }
    if (tempPatient != null && tempPatient.id) {
      return usersConverter.tempPatientApiToClientClinicUser(tempPatient);
    }
    return usersConverter.patientApiToClientClinicUser({});

  }

  async getAllFilteredPrescriptionsAsClinicUser(query, organisationId, organisationEmployeeId) {
    const response = await apiClient.getAllFilteredPrescriptionsAsClinicUser(query, organisationId, organisationEmployeeId)
    return response;
  }

  async requestRepeatPrescription(payload) {
    const response = await apiClient.requestRepeatPrescription(payload)
    return response;
  }

}

export const prescriptionService = new PrescriptionService();
